
.principal{
  height:100vh;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formModal{
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  box-shadow: 8px 8px #afadad2d;
  width: 25%;
}

.headerModal{
  background-color: rgba(128, 128, 128, 0.3);
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
}

.inputForm{
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  row-gap: 1.25rem;
}

.inputCar{
  padding-top: 0.75rem;
  border-width: 0px;
  color: rgba(75, 85, 99, 1);
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  width: 100%;
  border-bottom-width: 2px;
  border-color: rgba(229, 231, 235, 1);
}

.inputCar:focus{
  outline:none!important;
  border: none;
  border-bottom: solid;
  border-color: rgba(59, 130, 246, 1);
}

.label{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: solid;
  border-width: 1px;
}

.footerModal{
  display: flex;
  justify-content: flex-end;
  border-top-width: 1px;
  padding: 0.75rem;
  margin-top: 1.25rem;
}

.buttonModal{
  background-color: rgba(59, 130, 246, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  color: rgba(255, 255, 255, 1);
}

.buttonModal:hover{
  background-color: rgba(37, 99, 235, 1);
}
