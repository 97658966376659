#pageCounter {
    counter-reset: pageTotal;
  }
#pageCounter span {
    counter-increment: pageTotal; 
  }
#pageNumbers {
    counter-reset: currentPage;
    break-after: left;
  }
#pageNumbers #footerPage:before { 
    counter-increment: currentPage; 
    content: "Pagina " counter(currentPage) " din " counter(pageTotal); 
  }
#footerPage{
    position: absolute;
    bottom: 5px;
    left: 350px;
    color: gray;
    font-size: 11px;
}

.deviz-model{
    color: black;
    width: 794px;
    height: 1123px;
    border: 1px solid rgba(0, 0, 0, 0);

    break-after: left;
    position: relative;
    /* height: 100vh; Use 100% here to support printing more than a single page */
    /* margin: 0 !important;
    padding: 3 !important; */
    /* overflow: hidden; */
}

.interior{
    margin: 15px;
}

#footer{
    break-inside: avoid;
}

.deviz-button{
    margin-top: 10px;
}

.deviz-firma{
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
} 

.deviz-mic{
    font-size: 11px;
}

.deviz-mic2{
    font-size: 13px;
    break-inside: avoid;
}

.deviz-mic3{
    font-size: 9px;
}

.deviz-titlu{
    display: flex;
    justify-content: center;
    font-size: 18px;
} 

.deviz-date{
    display: flex;
    justify-content: space-between;
}

.container{
    border: 1px solid #000;
}


.deviz-model h1{
    font-size: 30px;
}
.deviz-model p{
    margin-top: 0;
    margin-bottom: 0 !important;
  
}

.deviz-model h2{
    margin-bottom: 0 !important;
}

.tableCorp{
    width: 100%;
}


@media only screen and (max-width:600px){
    .categories{
        width: 100%;
        overflow-x: scroll;
    }
}





