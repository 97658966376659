.headerCalendar{
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  
}

.titlu{
  margin-right: 2.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgba(107, 114, 128, 1);
  font-weight: inherit;
  margin-top: 6px;
}

.buttonCalendar{
  border-style: solid;
  border-width: 0.5px;
  border-radius: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1.25rem;
}

.schimbatLuna{
  cursor: pointer;
  color: rgba(75, 85, 99, 1);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  
}

.dataCalendar{
  margin-left: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgba(107, 114, 128, 1);
  font-weight: 700;
  margin-top: 10px;
}

.setari{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid;
  border-color: #d6d6d6;
  border-width: 1px;
}
