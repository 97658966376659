.rand{
  border-style: solid;
  border-width: 1px;
  display: flex;
  border-color: rgb(229 229 229);
}

.zile0{
  display: flex;
  align-items: center;
}

.zile{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0.5rem;
  border: solid;
  border-width: 0;
  border-right-width: 1px;
  border-color: rgb(229 229 229);
  width: 8rem;
}

.zile1{
  margin: 0 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  /* margin-top: 0.25rem; */
}

.zile2{
  margin: 0 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  /* padding: 0.25rem; */
  text-align: center;
  /* margin-top: 0.25rem;
  margin-bottom: 0.25rem; */

}

.zile3{
  background-color: rgba(37, 99, 235, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 9999px;
  width: 5rem;
}

.programare{
  padding: 0.25rem;
  margin-right: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  color: rgb(0, 0, 0);
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

