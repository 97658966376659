
button{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.programator{
  
  box-sizing: border-box;
  display: block;
  line-height: 24px;
  tab-size: 4;
  text-size-adjust: 100%;
  margin: 0;
}

textarea { resize: vertical; }

.header {
    position: sticky;
    top:0;
    width: 100%;
    height: 50px;
    background-color: rgb(245,245,245);
    z-index: 50;
    
  }

.body{
    display: flex;
    flex: 1;
    
}

