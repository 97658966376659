.checkboxRound {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 35%;
  vertical-align: middle;
  border: 2px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkboxRound input[type=checkbox]::before {
  content: '✓';
  color: white;
}